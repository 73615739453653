import type { Location } from 'react-router-dom';

import { i18n } from 'locale/i18n';
import { IFilterOption, IFilterType, IFilter } from 'types/interfaces';
import { IAssetFilterValues } from 'types/interfaces/IAssetFilterValues';

type FilterMetadata = {
  entityKey: keyof IAssetFilterValues;
  displayText: string;
  translationPrefix?: string;
};

const filtersMetadata: FilterMetadata[] = [
  {
    entityKey: 'priority_factors',
    displayText: i18n.t('pages.risks.resources.resourcesPage.table.filters.priorityFactors'),
  },
  {
    entityKey: 'asset_types',
    displayText: i18n.t('pages.risks.resources.resourcesPage.table.filters.type'),
    translationPrefix: 'assetType.',
  },
  {
    entityKey: 'teams',
    displayText: i18n.t('pages.risks.resources.resourcesPage.table.filters.teams'),
  },
];

const extractSelectedValuesFromUrl = (location: Location, entityKey: string, valueOptions: IFilterOption[]): IFilterOption[] => {
  const params = new URLSearchParams(location.search);
  const paramValue = params.get(entityKey);
  if (!paramValue) return [];
  const selectedValues = paramValue.split(',');
  return valueOptions.filter((option) => selectedValues.includes(option.value));
};

const convertToFilterOption = (value: string, translationPrefix: string = ''): IFilterOption => ({
  value,
  displayText: i18n.t(translationPrefix + value),
});

export const buildInitialFilters = (location: Location, assetFilterValues: IAssetFilterValues): IFilter[] => filtersMetadata.map((filter) => {
  const valueOptions = assetFilterValues[filter.entityKey].map((value) => convertToFilterOption(value, filter.translationPrefix || ''));
  return ({
    entityKey: filter.entityKey,
    displayText: filter.displayText,
    type: IFilterType.MULTI_SELECT,
    valueOptions,
    selectedValue: extractSelectedValuesFromUrl(location, filter.entityKey, valueOptions),
  });
});

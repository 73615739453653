import { FC, useState, useCallback } from 'react';

import { RisksBreadcrumbs } from 'pages/Resources/components/RisksBreadcrumbs/RisksBreadcrumbs';
import { Header } from 'pages/Resources/ResourcesPage/component/Header/Header';
import { ResourcesTable } from 'pages/Resources/ResourcesPage/component/ResourcesTable/ResourcesTable';
import { ResourcesTableFilters } from 'pages/Resources/ResourcesPage/component/ResourcesTable/ResourcesTableFilters/ResourcesTableFilters';
import { TotalResourcesCount } from 'pages/Resources/ResourcesPage/component/ResourcesTable/TotalResourcesCount/TotalResourcesCount';
import styles from 'pages/Resources/ResourcesPage/ResourcesPage.module.scss';
import { IFilter } from 'types/interfaces';

export const ResourcesPage: FC = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);

  const clearFilters = useCallback(() => {
    setFilters((prevFilters) => prevFilters.map((filter) => ({
      ...filter,
      selectedValue: [],
    })));
  }, []);

  return (
    <div className={styles.wrapper}>
      <RisksBreadcrumbs />

      <Header />

      <div className={styles.body}>
        <ResourcesTableFilters filters={filters} setFilters={setFilters} />

        <TotalResourcesCount />

        <ResourcesTable clearFilters={clearFilters} filters={filters} />
      </div>
    </div>
  );
};

import React, { FC, useCallback, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import styles from './ResourcesTable.module.scss';

import { AppliedFiltersHasNoData } from 'components/JitTable/components/AppliedFiltersHasNoData/AppliedFiltersHasNoData';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { constants } from 'globalConstants';
import { useGetTableColumns } from 'pages/Resources/ResourcesPage/component/ResourcesTable/useGetTableColumns';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { Queries } from 'types/enums/Queries';
import { IAsset, IFilter, IFilterOption } from 'types/interfaces';
import { IAssetFilterValues } from 'types/interfaces/IAssetFilterValues';

interface Props {
  filters: IFilter[];
  clearFilters: () => void;
}

const extractFilterToRequestParams = (entityKey: keyof IAssetFilterValues, filters: IFilter[]) => {
  const filter = filters.find((filterItem) => filterItem.entityKey === entityKey);
  if (!filter) return undefined;
  return (filter.selectedValue as IFilterOption[]).map((option) => option.value).join(',');
};

export const ResourcesTable: FC<Props> = ({ filters, clearFilters }) => {
  const navigate = useNavigate();
  const { getAssetsWithPagination } = useAssetService();
  const { BASE_ROUTE, RESOURCES, RESOURCES_LIST } = constants.routes.risks;

  const requestParams = useMemo(() => ({
    team: extractFilterToRequestParams('teams', filters),
    assetType: extractFilterToRequestParams('asset_types', filters),
    priorityFactors: extractFilterToRequestParams('priority_factors', filters),
  }), [filters]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    [Queries.Assets, requestParams],
    getAssetsWithPagination,
    {
      getNextPageParam: (response) => response?.metadata.after,
      enabled: !!filters?.length,
    },
  );
  const assets = useMemo(() => data?.pages?.flatMap((page) => page?.data || []) || [], [data?.pages]);

  const onRowClick = useCallback((asset: IAsset) => {
    navigate(`/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}/${asset.asset_id}`);
  }, [BASE_ROUTE, RESOURCES, RESOURCES_LIST, navigate]);

  const handleReachScrollEnd = useCallback(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, isLoading]);

  const { columns } = useGetTableColumns();
  const tableInstance = useGetTableInstance(columns, assets);

  // eslint-disable-next-line react/no-multi-comp
  const EmptyTableView = React.memo(() => <AppliedFiltersHasNoData clearFilters={clearFilters} entityName='resources' />);

  return (
    <div className={styles.table}>
      <JitTable
        cellPadding='0px 12px'
        className={styles.tableWrapper}
        EmptyTableView={EmptyTableView}
        handleReachScrollEnd={{
          callback: handleReachScrollEnd,
        }}
        headerCellPadding='0'
        isFetching={isFetchingNextPage}
        isLoading={isLoading || !filters.length}
        onSelectRow={onRowClick}
        rowHoverStyle={{ cursor: 'pointer' }}
        shouldDisplayWithPaper={false}
        showPagination={false}
        tableDesign='light'
        tableInstance={tableInstance}
      />

    </div>
  );
};

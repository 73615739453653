import { useCallback } from 'react';
import { QueryFunctionContext } from 'react-query';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { IAggregatedAssetStatistics, IAggregatedAssetStatisticsSnakeCase, IAsset, IUpdateAssetRequest, IInitialScanStats } from 'types/interfaces';
import { IAssetFilterValues } from 'types/interfaces/IAssetFilterValues';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const useAssetService = () => {
  const { client } = useClient();
  const getAssets = useCallback(async (teamName?: string) => {
    const url = getApiUrls.assetService.getAssets();
    return client.get<IAsset[]>(
      {
        url,
        requestConfig: {
          params: {
            team: teamName,
          },
        },
        allowedStatuses: [200, 404],
      },
    );
  }, [client]);
  const getAssetsFilterValues = useCallback(async (): Promise<IAssetFilterValues | undefined> => {
    const response = await client.get<IAssetFilterValues>(
      {
        url: getApiUrls.assetService.getAssetsFilterValues(),
        allowedStatuses: [200],
        requestConfig: {
          params: {
            is_active: true,
            is_covered: true,
          },
        },
      },
    );
    if (response?.status === 200) {
      return response.data as IAssetFilterValues;
    }
    throw new Error('Error fetching assets filter values');
  }, [client]);

  type GetAssetsParams = {
    team?: string;
    assetType?: string;
    priorityFactors?: string;
  };
  const getAssetsWithPagination = useCallback(async ({
    queryKey,
    pageParam,
  }: QueryFunctionContext<[string, GetAssetsParams]>): Promise<IPaginatedResponse<IAsset> | undefined> => {
    const [, params] = queryKey;
    const response = await client.get<IPaginatedResponse<IAsset>>(
      {
        url: getApiUrls.assetService.getAssets(),
        allowedStatuses: [200],
        requestConfig: {
          headers: {
            API_VERSION: 'v2',
          },
          params: {
            is_covered: true,
            team: params.team,
            asset_type: params.assetType,
            priority_factors: params.priorityFactors,
            after: pageParam,
            limit: 20,
          },
        },
      },
    );
    if (response?.status === 200) {
      return response.data as IPaginatedResponse<IAsset>;
    }
    throw new Error('Error fetching assets');
  }, [client]);

  const updateMultipleAssets = useCallback(async (assets: IAsset[]) => {
    const url = getApiUrls.assetService.updateMultipleAssets();
    const assetsPatch: IUpdateAssetRequest[] = assets.map((asset) => ({
      asset_id: asset.asset_id,
      new_name: asset.asset_name,
      is_covered: asset.is_covered,
      tags: asset.tags,
    }));
    return client.post<IAsset[]>({
      url,
      allowedStatuses: [200, 404],
      requestConfig: {
        data: assetsPatch,
      },
    });
  }, [client]);

  const getAssetsStatistics = useCallback(
    async ({
      queryKey,
    }: QueryFunctionContext<[string, string?]>): Promise<IAggregatedAssetStatistics[] | undefined> => {
      const [, aggregationKey] = queryKey;
      const url = getApiUrls.assetService.getAssetsStatistics(aggregationKey);
      const response = await client.get<IAggregatedAssetStatisticsSnakeCase[]>({
        url,
        allowedStatuses: [200, 400, 403],
      });

      if (response?.status === 200) {
        return camelizeSnakeCaseKeys(response.data) as IAggregatedAssetStatistics[];
      }
      throw new Error('Error fetching assets statistics');
    },
    [client],
  );

  const getInitialScanStats = useCallback(async () => {
    const url = getApiUrls.assetService.getInitialScanStats();
    const response = await client.get<IInitialScanStats[]>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response.data) as IInitialScanStats[];
    }

    return undefined;
  }, [client]);

  return {
    getAssets,
    getAssetsWithPagination,
    updateMultipleAssets,
    getAssetsStatistics,
    getAssetsFilterValues,
    getInitialScanStats,
  };
};

import { FC } from 'react';

import styles from './PriorityBreakdown.module.scss';

import { PriorityScore } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText, TextSize } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  critical?: number;
  high?: number;
  medium?: number;
  textSize?: TextSize;
}

interface Criticality {
  label: string;
  value: number;
  color: string;

}

export const PriorityBreakdown: FC<Props> = ({ critical, high, medium, textSize = 'xs' }) => {
  const criticality: Criticality[] = [
    {
      label: 'Critical',
      value: critical,
      color: colors.criticalPriority,
    },
    {
      label: 'High',
      value: high,
      color: colors.highPriority,
    },
    {
      label: 'Medium',
      value: medium,
      color: colors.mediumPriority,
    },
  ].filter((crit) => crit.value !== undefined) as Criticality[];

  const renderItem = (item: Criticality, textColor: string = colors.white) => (
    <div key={item.label} className={styles.findingsBreakdownItem} data-testid='priorityBreakdownItem'>
      <JitIcon color={item.color} icon={PriorityScore} size={12} />

      <JitText bold color={textColor} size={textSize} text={item.value} />
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.findingsBreakdown}>
        {criticality.map((item) => (
          renderItem(item)
        ))}
      </div>
    </div>
  );
};

import { isEmpty } from 'lodash';
import { FC, useCallback, useMemo } from 'react';

import styles from './FindingsTable.module.scss';

import { RowActions } from 'components/FindingsTable/components/RowActions/RowActions';
import { useFindingTableContext } from 'components/FindingsTable/FindingsTableContext/FindingsTableContext';
import { IgnoreFindingsDialog } from 'components/IgnoreFindingsDialog/IgnoreFindingsDialog';
import { JitSlidingPanel } from 'components/JitSlidingPanel/JitSlidingPanel';
import { JitTable } from 'components/JitTable/JitTable';
import { FindingDetails, FindingsSelectedFindings } from 'pages/FindingsPage/components';
import { EmptyFindingsTable } from 'pages/FindingsPage/components/EmptyFindingTable/EmptyFindingsTable';
import { MAX_FINDINGS_IN_PAGE } from 'pages/FindingsPage/constants';

interface Props {
  isLoading?: boolean;
  EmptyTableView?: FC;
}

export const FindingsTableContent: FC<Props> = ({ isLoading, EmptyTableView }) => {
  const {
    totalFindingsAmount,
    onSelectRow,
    tableInstance,
    paginationManager,
    selectedRow,
    limit,
    selectedFindingIds,
    displayIgnoreByType,
    completeIgnoreFindings,
    isIgnoreLoading,
    onDialogClose,
    isIgnoreFindingsDialogOpen,
    findingsToIgnore,
    updateSelectedRow,
  } = useFindingTableContext();
  const displaySelectedFindings = useMemo(() => !isEmpty(selectedFindingIds) && !paginationManager.isLoading, [selectedFindingIds, paginationManager.isLoading]);

  const handleClosePanel = useCallback(() => {
    updateSelectedRow(null);
  }, [updateSelectedRow]);

  return (
    <div className={styles.container}>
      <div className={styles.findingsTable}>
        <JitTable
          cellPadding='0px 12px'
          className={styles.tableWrapper}
          ElementToShowOnRowHover={RowActions}
          EmptyTableView={EmptyTableView || EmptyFindingsTable}
          headerCellPadding='0'
          isLoading={isLoading || paginationManager.isLoading}
          onSelectRow={onSelectRow}
          pageMaxLength={MAX_FINDINGS_IN_PAGE}
          rowHoverStyle={{ cursor: 'pointer' }}
          selectedRow={selectedRow}
          shouldDisplayWithPaper={false}
          showPagination={!limit}
          tableDesign='light'
          tableInstance={tableInstance}
          totalRecordsAmount={totalFindingsAmount || undefined}
        />
      </div>

      {selectedRow && (
        <JitSlidingPanel closePanel={handleClosePanel} isOpen={!!selectedRow} variant='light'>
          <FindingDetails onClosePanel={handleClosePanel} />
        </JitSlidingPanel>
      )}

      {displaySelectedFindings && (
        <FindingsSelectedFindings />
      )}

      <IgnoreFindingsDialog
        displayIgnoreByType={displayIgnoreByType}
        findingType={displayIgnoreByType ? findingsToIgnore[0].name : undefined}
        ignoreFunction={completeIgnoreFindings}
        isIgnoredLoading={isIgnoreLoading}
        onClose={onDialogClose}
        open={isIgnoreFindingsDialogOpen}
      />
    </div>
  );
};

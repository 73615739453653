import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { staticAuthModeOptions } from '../constants';

import { ConfigurationsTargetUrlField } from './ConfigurationsTargetUrlField';
import styles from './ConfigurationsZap.module.scss';
import { ConfigurationsZapExtraFields } from './ConfigurationsZapExtraFields';
import { ConfigurationsZapField } from './ConfigurationsZapField';
import { HandleChangeType } from './types';

import { ConfigurationsCheckboxField } from '.';

import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { ApplicationNameValidator } from 'context/ConfigurationsContext/validators/ApplicationNameValidator';
import { ZapApiValidator, ZapDomainValidator, ZapUrlValidator, ZapWebValidator } from 'context/ConfigurationsContext/validators/ZapValidators';
import { ConfigurePlanItemDialogButtons } from 'pages/PlanPage/components/ConfigurePlanItemDialogButtons/ConfigurePlanItemDialogButtons';
import { AssetType } from 'types/enums/AssetType';
import { MenuItemKeyType, ZapApplication, ZapAuthenticationConfigType, ZapAuthMode } from 'types/interfaces';

interface Props {
  type: ZapAuthenticationConfigType;
  onSave: () => void;
  onCancel: () => void;
  selectedApplication: ZapApplication;
  setSelectedApplication: Dispatch<SetStateAction<ZapApplication | undefined>>;
  applicationNameBeforeEditing: string;
}

export const ConfigurationsZapForm: FC<Props> = ({ type, onSave, onCancel, selectedApplication, setSelectedApplication, applicationNameBeforeEditing }) => {
  const { uiShowZapAuthenticationMode } = useFlags();
  const { configurations } = useConfigurationsContext();
  const [isValidConfig, setIsValidConfig] = useState(false);

  const isUrlValid = useMemo(() => ZapUrlValidator(false, selectedApplication.target_url), [selectedApplication.target_url]);
  const isDomainValid = useMemo(() => ZapDomainValidator(selectedApplication.api_domain), [selectedApplication.api_domain]);
  const isAuthChecked = useMemo(
    () => selectedApplication?.authentication_mode !== ZapAuthMode.NonAuthenticated,
    [selectedApplication?.authentication_mode],
  );

  const handleChange: HandleChangeType = useCallback(
    (fieldName, fieldValue) => {
      if (!selectedApplication) return;
      // intentionally validate previous exists
      setSelectedApplication((prev) => (prev ? { ...prev,
        [fieldName]: fieldValue } : undefined));
    },
    [selectedApplication, setSelectedApplication],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      handleChange(name as keyof ZapApplication, value);
    },
    [handleChange],
  );

  const onChangeExcludedPaths = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const excludePathsArray = value ? value.split(',').map((path) => path.trim()) : [];
    handleChange('exclude_paths', excludePathsArray);
  }, [handleChange]);

  const onChangeAuthenticationMode = useCallback((newAuthMode: ZapAuthMode) => {
    handleChange('authentication_mode', newAuthMode);
  }, [handleChange]);

  const onChangeAuthenticationModeCheckbox = useCallback((isChecked: boolean) => {
    if (uiShowZapAuthenticationMode) {
      const updatedAuthMode = isChecked ? ZapAuthMode.FormBased : ZapAuthMode.NonAuthenticated;
      onChangeAuthenticationMode(updatedAuthMode);
    }
  }, [uiShowZapAuthenticationMode, onChangeAuthenticationMode]);

  const handleAuthModeChange = useCallback(
    ({ itemKey }: { itemKey: MenuItemKeyType }) => {
      onChangeAuthenticationMode(itemKey as ZapAuthMode);
    },
    [onChangeAuthenticationMode],
  );

  const authModeDropdownOptions = useMemo(
    () => staticAuthModeOptions.map((option) => ({
      ...option,
      isSelected: selectedApplication?.authentication_mode === option.itemKey,
    })),
    [selectedApplication],
  );

  const isApplicationNameValid = useMemo(
    () => selectedApplication.application_name === applicationNameBeforeEditing
    || ApplicationNameValidator(selectedApplication.application_name, configurations),
    [selectedApplication.application_name, applicationNameBeforeEditing, configurations],
  );

  useEffect(() => {
    if (selectedApplication) {
      const isConfigValid = type === AssetType.WEB
        ? ZapWebValidator({ applications: [selectedApplication] })
        : ZapApiValidator({ applications: [selectedApplication] });

      setIsValidConfig(isConfigValid && isApplicationNameValid);
    }
  }, [selectedApplication, type, isApplicationNameValid]);

  return (
    <>
      <ConfigurationsZapField
        dataTestid='configurations-zap-field-application-name'
        handleChangeInput={handleInputChange}
        inputField='application_name'
        inputValue={selectedApplication.application_name}
        isValid={isApplicationNameValid}
        type={type}
      />

      {type === AssetType.API && (
      <ConfigurationsTargetUrlField
        dataTestid='configurations-zap-api-field-target-url'
        handleChange={handleChange}
        handleChangeInput={handleInputChange}
        inputField='target_url'
        inputValue={selectedApplication.target_url}
        type={type}
      />
      )}

      {type === AssetType.WEB && (
      <ConfigurationsZapField
        dataTestid='configurations-zap-web-field-target-url'
        handleChangeInput={handleInputChange}
        inputField='target_url'
        inputValue={selectedApplication.target_url}
        isValid={isUrlValid}
        type={type}
      />
      )}

      <ConfigurationsZapField
        dataTestid='configurations-zap-field-exclude-urls'
        handleChangeInput={onChangeExcludedPaths}
        inputField='exclude_paths'
        inputValue={selectedApplication.exclude_paths?.join(', ') || ''}
        isMultiLine
        type={type}
      />

      <ConfigurationsZapField
        handleChangeInput={handleInputChange}
        inputField='api_domain'
        inputValue={selectedApplication.api_domain || ''}
        isValid={isDomainValid}
        type={type}
      />

      <ConfigurationsCheckboxField
        disabled={!uiShowZapAuthenticationMode}
        isChecked={isAuthChecked && uiShowZapAuthenticationMode}
        label='configurations.zap.enableAuthentication.label'
        onChange={onChangeAuthenticationModeCheckbox}
        testid='zap-authentication'
        tooltipText={uiShowZapAuthenticationMode ? 'configurations.zap.enableAuthentication.info_enabled' : 'configurations.zap.enableAuthentication.info_disabled'}
      />

      {isAuthChecked && uiShowZapAuthenticationMode && (
        <>
          <div className={styles.dropdownWrapper}>
            <JitDropdownNew
              displayText={`configurations.zap.web.authModes.${selectedApplication.authentication_mode}`}
              menuItems={authModeDropdownOptions}
              onMenuItemClick={handleAuthModeChange}
              width={240}
            />
          </div>

          <div className={styles.extraFieldsWrapper}>
            <ConfigurationsZapExtraFields
              configurations={selectedApplication}
              handleChange={handleChange}
              handleChangeInput={handleInputChange}
              type={type}
            />
          </div>
        </>
      )}

      <ConfigurePlanItemDialogButtons
        className={styles.buttonsWrapper}
        customCancelText='configurations.zap.backButton'
        disabledFinishButtonTooltip='pages.plan.configurationsDialog.missingField'
        finishText='configurations.zap.saveApplication'
        isFinishButtonDisabled={!isValidConfig}
        onCancelClicked={onCancel}
        onFinish={onSave}
      />
    </>
  );
};

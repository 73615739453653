import { FC, useState } from 'react';

import { SeeDetailsButton } from '../SeeDetailsButton/SeeDetailsButton';

import styles from './ResourceBreakdownCard.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PriorityFactorsBox } from 'components/PriorityFactorsBox/PriorityFactorsBox';
import { ResourcesStatistics } from 'pages/Resources/ResourcesDashboardPage/components/ResourcesStatistics/ResourcesStatistics';
import { IAggregatedAssetStatistics } from 'types/interfaces';
import { capitalizeFirstLetter } from 'utils';

export const ResourceBreakdownCard: FC<IAggregatedAssetStatistics> = (resourceStatistic) => {
  const {
    aggregationKey,
    count,
    averagePriorityScore,
    buckets,
  } = resourceStatistic;

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const description = `Deployed in the ${aggregationKey} environment`;
  const tag = (
    <PriorityFactorsBox factors={[{
      key: aggregationKey,
      displayName: capitalizeFirstLetter(aggregationKey),
    }]}
    />
  );
  return (
    <div
      className={styles.card}
      data-testid='resource-breakdown-card'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.cardMainContent}>
        <JitText bold size='xxxxl' style={{ padding: '14px' }} text={count} />

        <div className={styles.cardMainContentDescription}>

          {tag}

          <JitText align='center' display='flex' muted size='m' text={description} />
        </div>
      </div>

      <div className={styles.cardLowerContainer}>
        {isHovered
          ? <SeeDetailsButton aggregationKey={aggregationKey} />
          : <ResourcesStatistics priorityBreakdown={buckets} score={averagePriorityScore} />}
      </div>

    </div>
  );
};

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { INITIAL_ONBOARDING_CHECK_SLUG, SCM_INTEGRATION_CHECK_SLUG } from './constant';
import { useHandlePreferencesUpdateWebSocketNotification } from './tenantHooks/useHandlePreferencesUpdateWebSocketNotification';
import { useHandleAWSVersionError } from './tenantHooks/useHandleVersionError';

import { AppLoadingBar } from 'components/AppLoadingBar/AppLoadingBar';
import { TenantContext } from 'context/TenantContext/TenantContext';
import { useListenToInstallationsWebsocket } from 'context/TenantContext/tenantHooks/useListenToInstallationsWebsocket';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';
import { setGlobalScmVendor } from 'locale/RequestTypePostProcessor';
import { Check } from 'pages/QuickStartGuide/types';
import { useTenantService } from 'services/TenantService/useTenantService';
import { Vendor, WebSocketNotificationTopics } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { IInstallation, IIntegration, IPreferences, IWebsocketNotification } from 'types/interfaces';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const TenantProvider: FC = ({ children }) => {
  const [preferences, setPreferences] = useState<IPreferences>();
  const [isPreferencesLoading, setIsPreferencesLoading] = useState<boolean>(false);
  const [integrations, setIntegrations] = useState<IIntegration[]>([]);
  const [checks, setChecks] = useState<Check[]>([]);
  const [isIntegrationsLoading, setIsIntegrationsLoading] = useState<boolean>(false);
  const [isAddingNewOrganization, setIsAddingNewOrganization] = useState<boolean>(false);

  const { websocketSubscribe } = useWebsocketSubscribe();
  const { installations, isLoadingInstallation, hasTriedFetchInstallations } = useListenToInstallationsWebsocket();
  const { handlePreferencesUpdateWebSocketNotification } = useHandlePreferencesUpdateWebSocketNotification({ setPreferences });
  const { fetchIntegrations, fetchPreferences, fetchQuickstartGuideData, updateCheckStatus } = useTenantService();

  const hasUserPreferencesInitialized = useMemo(() => !!preferences && !!preferences.display, [preferences]);

  const awsInstallation = useMemo(() => installations?.find((installation) => installation.vendor === Vendor.AWS), [installations]);
  const { handleAWSVersionError } = useHandleAWSVersionError(awsInstallation);

  const { data: quickstartGuideData, isLoading: isChecksLoading } = useQuery([Queries.QuickstartChecks], fetchQuickstartGuideData);
  const sections = useMemo(() => quickstartGuideData?.sections || [], [quickstartGuideData]);
  const initialOnboardingCheck = useMemo(() => checks?.find((check) => check.checkSlug === INITIAL_ONBOARDING_CHECK_SLUG), [checks]);
  const integrateSCMCheck = useMemo(() => checks?.find((check) => check.checkSlug === SCM_INTEGRATION_CHECK_SLUG), [checks]);
  const initialOnboardingCompleted = useMemo(() => !!initialOnboardingCheck?.isCompleted, [initialOnboardingCheck?.isCompleted]);
  const SCMIntegrationCompleted = useMemo(() => !!integrateSCMCheck?.isCompleted, [integrateSCMCheck?.isCompleted]);
  const handleCheckUpdateNotification = useCallback((notification: IWebsocketNotification<Check>) => {
    const updatedChecks: Check[] = camelizeSnakeCaseKeys(notification.message.updated) as Check[];
    setChecks((prevChecks) => prevChecks.map((check) => {
      const updatedCheck = updatedChecks.find((updated) => updated.checkSlug === check.checkSlug);
      if (updatedCheck) {
        return { ...check,
          isCompleted: updatedCheck.isCompleted };
      }
      return check;
    }));
  }, []);

  useEffect(() => {
    if (quickstartGuideData?.checks) {
      setChecks(quickstartGuideData.checks);
    }
  }, [quickstartGuideData]);

  const fetchMergedPreferences = useCallback(async () => {
    setIsPreferencesLoading(true);
    const res = await fetchPreferences();
    setPreferences(res?.data);
    setIsPreferencesLoading(false);
  }, [fetchPreferences]);

  const initPreferences = useCallback(async () => {
    if (!preferences && !isPreferencesLoading) {
      fetchMergedPreferences();
    }
  }, [fetchMergedPreferences, isPreferencesLoading, preferences]);

  const initUserPreferences = useCallback(async () => {
    if (!hasUserPreferencesInitialized && !isPreferencesLoading) {
      fetchMergedPreferences();
    }
  }, [fetchMergedPreferences, hasUserPreferencesInitialized, isPreferencesLoading]);

  const initIntegrations = useCallback(async () => {
    setIsIntegrationsLoading(true);
    const res = await fetchIntegrations();
    setIntegrations(res?.data || []);
    setIsIntegrationsLoading(false);
  }, [fetchIntegrations]);

  const getIntegrations = useCallback(() => {
    if (!integrations.length) {
      initIntegrations();
    }
    return integrations;
  }, [integrations, initIntegrations]);

  useEffect(() => {
    handleAWSVersionError();
  }, [handleAWSVersionError]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Preferences, handlePreferencesUpdateWebSocketNotification);
  }, [handlePreferencesUpdateWebSocketNotification, websocketSubscribe]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Check, handleCheckUpdateNotification);
  }, [handleCheckUpdateNotification, websocketSubscribe]);

  const githubInstallation = useMemo(() => installations.find((installation) => installation.vendor === constants.GITHUB), [installations]);
  const gitlabInstallation = useMemo(() => installations.find((installation) => installation.vendor === constants.GITLAB), [installations]);

  const isGithubIntegrated = useMemo(() => !!githubInstallation?.centralized_repo_asset_id, [githubInstallation]);
  const isGitlabIntegrated = useMemo(() => !!gitlabInstallation?.centralized_repo_asset_id && !!gitlabInstallation.vendor_attributes?.repository_selection, [gitlabInstallation]);
  const scmInstallations = useMemo(() => installations.filter((installation) => installation.centralized_repo_asset_id), [installations]);

  const currentScmVendor = useMemo(() => scmInstallations[0]?.vendor, [scmInstallations]);
  // We currently support only one SCM vendor. If this changes, this logic will need to be updated.

  useEffect(() => {
    if (currentScmVendor) {
      setGlobalScmVendor(currentScmVendor);
      i18n.reloadResources();
    }
  }, [currentScmVendor]);

  const getInstallationForSCMVendor = useCallback((vendor: string): IInstallation | undefined => {
    if (!scmInstallations?.length) return undefined;

    return scmInstallations.find((installation) => installation.vendor === vendor);
  }, [scmInstallations]);

  const isScmVendorIntegrated = useCallback((vendor: string) => installations.some((installation) => installation.vendor === vendor && installation.centralized_repo_asset_id), [installations]);
  const isOtherScmVendorIntegrated = useCallback((vendor: string) => installations.some((installation) => installation.vendor !== vendor && installation.centralized_repo_asset_id), [installations]);
  const isAnyScmVendorIntegrated = useMemo(
    () => isGithubIntegrated || isOtherScmVendorIntegrated(constants.GITHUB),
    [isGithubIntegrated, isOtherScmVendorIntegrated],
  );

  const setCheckAsCompleted = useCallback(async (checkSlug) => {
    updateCheckStatus({
      checkSlug,
      isCompleted: true,
    });
  }, [updateCheckStatus]);

  const value = useMemo(() => ({
    installations,
    githubInstallation,
    gitlabInstallation,
    hasTriedFetchInstallations,
    preferences,
    setPreferences,
    isPreferencesLoading,
    setIsPreferencesLoading,
    initPreferences,
    isLoadingInstallation,
    getIntegrations,
    initUserPreferences,
    isGithubIntegrated,
    isGitlabIntegrated,
    scmInstallations,
    isScmVendorIntegrated,
    isOtherScmVendorIntegrated,
    isAnyScmVendorIntegrated,
    currentScmVendor,
    getInstallationForSCMVendor,
    initialOnboardingCompleted,
    checks,
    sections,
    isChecksLoading,
    setCheckAsCompleted,
    SCMIntegrationCompleted,
    isIntegrationsLoading,
    isAddingNewOrganization,
    setIsAddingNewOrganization,
  }), [
    installations,
    githubInstallation,
    gitlabInstallation,
    hasTriedFetchInstallations,
    preferences,
    isPreferencesLoading,
    initPreferences,
    isLoadingInstallation,
    getIntegrations,
    initUserPreferences,
    isGithubIntegrated,
    isGitlabIntegrated,
    scmInstallations,
    isScmVendorIntegrated,
    isOtherScmVendorIntegrated,
    isAnyScmVendorIntegrated,
    currentScmVendor,
    getInstallationForSCMVendor,
    initialOnboardingCompleted,
    checks,
    sections,
    isChecksLoading,
    setCheckAsCompleted,
    SCMIntegrationCompleted,
    isIntegrationsLoading,
    isAddingNewOrganization,
    setIsAddingNewOrganization,
  ]);

  if (isChecksLoading) {
    return <AppLoadingBar />;
  }

  return (
    <TenantContext.Provider value={value}>
      {children}
    </TenantContext.Provider>
  );
};

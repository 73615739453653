import { FC, useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import buttonStyles from '../ButtonMainComponent.module.scss';

import { SLUGS_TO_DISPLAY } from './constants';
import { useAssetInitialStatsWebsocket } from './hooks/useAssetInitialStatsWebsocket';
import { ProgressBarPanel } from './ProgressBarPanel';
import styles from './SecurityPlansProgress.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context/TenantContext';
import { EXPLORE_APPSEC_PLAN_CHECK_SLUG } from 'context/TenantContext/constant';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { constants } from 'globalConstants';
import { useAssetService } from 'services/AssetsService/useAssetService';
import colors from 'themes/colors.module.scss';
import { WebSocketNotificationTopics } from 'types/enums';
import { Queries } from 'types/enums/Queries';

export const SecurityPlansProgress: FC = () => {
  const navigate = useNavigate();
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { getInitialScanStats } = useAssetService();
  const { setCheckAsCompleted } = useTenantContext();

  const { handleAssetInitialStatsWebsocketNotification } = useAssetInitialStatsWebsocket();

  const { routes: { PIPELINES, PLANS } } = constants;

  const { data: assetStats, isLoading } = useQuery(Queries.InitialScanStats, getInitialScanStats);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.AssetScanStats, handleAssetInitialStatsWebsocketNotification);
  }, [handleAssetInitialStatsWebsocketNotification, websocketSubscribe]);

  const isAllCompleted = useMemo(() => assetStats?.every((item) => item.inProgressAssetCount === 0), [assetStats]);
  const title = useMemo(() => (isAllCompleted
    ? 'pages.quickStart.checks.securityPlansProcess.completed.title'
    : 'pages.quickStart.checks.securityPlansProcess.scanning.title'
  ), [isAllCompleted]);

  const handleResultsButtonClick = useCallback(() => {
    setCheckAsCompleted(EXPLORE_APPSEC_PLAN_CHECK_SLUG);
    navigate(`/${PIPELINES}`);
  }, [PIPELINES, navigate, setCheckAsCompleted]);

  const handleExploreButtonClick = () => navigate(`/${PLANS}`);

  const slugsToDisplay = useMemo(() => assetStats?.filter((item) => SLUGS_TO_DISPLAY.includes(item.planItemSlug)), [assetStats]);

  if (isLoading || !slugsToDisplay?.length) return null;

  return (
    <div className={styles.wrapper} data-testid='security-plans-progress'>
      <JitText
        color={colors.gray}
        data-testid='security-plans-progress-title'
        size='xs'
        text={title}
      />

      <div className={styles.processBarsWrapper} data-testid='security-plans-progress-bars'>
        {slugsToDisplay.map((item) => (
          <ProgressBarPanel
            key={item.planItemSlug}
            completed={item.completedAssetCount}
            title={item.planItemDisplayName}
            total={item.completedAssetCount + item.inProgressAssetCount}
          />
        ))}
      </div>

      <div className={styles.buttons} data-testid='security-plans-progress-buttons'>
        <JitGradientButton
          data-testid='security-plans-progress-results-button'
          onClick={handleResultsButtonClick}
        >
          <div className={buttonStyles.buttonContent}>
            <JitText
              bold
              data-testid='security-plans-progress-results-text'
              fontFamily='Inter, serif'
              size='s'
              text='pages.quickStart.checks.securityPlansProcess.resultsButton'
            />
          </div>
        </JitGradientButton>

        <JitText
          color={colors.iris}
          data-testid='security-plans-progress-explore-button'
          onClick={handleExploreButtonClick}
          text='pages.quickStart.checks.securityPlansProcess.exploreButton'
        />
      </div>
    </div>
  );
};

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './SeeDetailsButton.module.scss';

import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';

interface Props {
  aggregationKey: string;
}

export const SeeDetailsButton: FC<Props> = ({ aggregationKey }) => {
  const { BASE_ROUTE, RESOURCES, RESOURCES_LIST } = constants.routes.risks;
  const navigate = useNavigate();
  const onClick = () => {
    const params = new URLSearchParams();
    params.set('priority_factors', aggregationKey);
    const baseUrl = `/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}`;
    navigate(`${baseUrl}?${params.toString()}`);
  };
  return (
    <div
      className={styles.button}
      onClick={onClick}
      role='button'
      tabIndex={0}
    >
      <JitText size='s' text='pages.risks.resources.resourcesDashboard.seeDetails' />
    </div>
  );
};

import { FC, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { JitFilters } from 'components/JitFilters/JitFilters';
import { buildInitialFilters } from 'pages/Resources/ResourcesPage/component/ResourcesTable/ResourcesTableFilters/utils';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { Queries } from 'types/enums/Queries';
import { IFilter, IFilterOption } from 'types/interfaces';

interface Props {
  filters: IFilter[];
  setFilters: Dispatch<SetStateAction<IFilter[]>>;
}

export const ResourcesTableFilters: FC<Props> = ({ filters, setFilters }) => {
  const { getAssetsFilterValues } = useAssetService();
  const { data: assetFilterValues } = useQuery(
    [Queries.AssetFilterValues],
    getAssetsFilterValues,
  );

  const location = useLocation();
  const navigate = useNavigate();

  const loadFilters = useCallback(() => {
    if (!assetFilterValues || filters.length > 0) return;

    setFilters(buildInitialFilters(location, assetFilterValues));
  }, [assetFilterValues, filters.length, location, setFilters]);

  useEffect(() => {
    loadFilters();
  }, [loadFilters]);

  const updateFilter = (updatedFilter: IFilter) => {
    setFilters((prevFilters) => {
      const newFilters = prevFilters.map((filter) => (filter.entityKey === updatedFilter.entityKey ? updatedFilter : filter));

      const params = new URLSearchParams(location.search);
      newFilters.forEach((filter) => {
        if (Array.isArray(filter.selectedValue) && filter.selectedValue.length > 0) {
          const values = filter.selectedValue.map((option: IFilterOption) => option.value).join(',');
          params.set(filter.entityKey, values);
        } else {
          params.delete(filter.entityKey);
        }
      });

      navigate(`${location.pathname}?${params.toString()}`, { replace: true });

      return newFilters;
    });
  };

  return (
    <JitFilters
      filters={filters}
      shouldCloseOnItemClick={false}
      updateFilter={updateFilter}
    />
  );
};

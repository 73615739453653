import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';

import { useTempConfigurationStateManager } from '../hooks/useTempConfigurationStateManager';

import { ConfigurationsTargetUrlField } from './ConfigurationsTargetUrlField';
import styles from './ConfigurationsZap.module.scss';
import { ConfigurationsZapField } from './ConfigurationsZapField';

import { ConfigurationsCheckboxField } from 'components/Configurations/Zap/components/ConfigurationsCheckboxField';
import { ConfigurationsZapExtraFields } from 'components/Configurations/Zap/components/ConfigurationsZapExtraFields';
import {
  authModeToExtraFieldsInitialState,
  webApplicationCommonInitialState,
  webApplicationFormBaseAuthInitialState,
} from 'components/Configurations/Zap/constants';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { useGetAreSettingsProperlySet } from 'context/ConfigurationsContext/hooks/useGetAreSettingsProperlySet';
import { ApplicationNameValidator } from 'context/ConfigurationsContext/validators/TempApplicationNameValidator';
import { ZapDomainValidator, ZapUrlValidator } from 'context/ConfigurationsContext/validators/ZapValidators';
import { usePlansContext } from 'context/PlansContext/PlansContext';
import { AssetType } from 'types/enums/AssetType';
import { IConfigurations, ZapApplication, ZapAuthenticationConfigType, ZapAuthMode } from 'types/interfaces';

interface Props {
  type: ZapAuthenticationConfigType;
  setIsDoneStep: Dispatch<SetStateAction<boolean>>;
  planItemSlug: string;
  onChangeConfiguration: Dispatch<SetStateAction<IConfigurations>>;
}

export const TempConfigurationsZap: FC<Props> = ({ type, setIsDoneStep, planItemSlug, onChangeConfiguration }) => {
  const {
    getCurrentConfigurations, handleChangeInput, handleChange, deleteConfigurationKey,
  } = useTempConfigurationStateManager(webApplicationFormBaseAuthInitialState(type), onChangeConfiguration);
  const { plans } = usePlansContext();
  const { configurations } = useConfigurationsContext();
  const { getAreSettingsProperlySet } = useGetAreSettingsProperlySet();
  const { uiShowZapAuthenticationMode } = useFlags();
  const currentConfiguration = getCurrentConfigurations() as ZapApplication;
  const {
    application_name: applicationName,
    exclude_paths: excludePaths,
    target_url: targetUrl,
    authentication_mode: authenticationMode,
    api_domain: apiDomain,
  } = currentConfiguration;

  const onChangeExcludedPaths = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      handleChange('exclude_paths', []);
    } else {
      const excludePathsArray = event.target.value.split(',').map((path) => path.trim());
      handleChange('exclude_paths', excludePathsArray);
    }
  };

  const planItem = useMemo(() => {
    const foundPlan = Object.values(plans).find((plan) => plan.items && plan.items[planItemSlug]);
    return foundPlan ? foundPlan.items?.[planItemSlug] : undefined;
  }, [plans, planItemSlug]);

  const isMissingConfig = useMemo(() => planItem && !getAreSettingsProperlySet(planItem), [planItem, getAreSettingsProperlySet]);

  useEffect(() => {
    setIsDoneStep(!isMissingConfig);
  }, [isMissingConfig, setIsDoneStep]);

  const onChangeAuthenticationMode = (newAuthMode: ZapAuthMode) => {
    const commonFields = webApplicationCommonInitialState;
    const newAuthModeExtraFields = authModeToExtraFieldsInitialState[newAuthMode];

    // Delete redundant Keys when changing auth mode
    Object.keys(currentConfiguration).forEach((key) => {
      const isRelevantKey = Object.keys(commonFields).includes(key) || Object.keys(newAuthModeExtraFields).includes(key);
      if (!isRelevantKey) {
        deleteConfigurationKey(key);
      }
    });
    // Reset the unique fields for each auth mode
    Object.entries(newAuthModeExtraFields).forEach(([key, value]) => {
      handleChange(key, value);
    });

    // update the auth mode
    handleChange('authentication_mode', newAuthMode);
  };

  const tooltipText = useMemo(() => (uiShowZapAuthenticationMode ? 'configurations.zap.enableAuthentication.info_enabled'
    : 'configurations.zap.enableAuthentication.info_disabled'), [uiShowZapAuthenticationMode]);

  const onChangeAuthenticationModeCheckbox = (isChecked: boolean) => {
    if (uiShowZapAuthenticationMode) {
      const updatedAuthMode = isChecked ? ZapAuthMode.FormBased : ZapAuthMode.NonAuthenticated;
      onChangeAuthenticationMode(updatedAuthMode);
    }
  };

  const isAuthChecked = authenticationMode !== ZapAuthMode.NonAuthenticated;

  const authModeDropdownOptions = [
    ZapAuthMode.FormBased,
    ZapAuthMode.LocalStorage,
    ZapAuthMode.CustomCookie,
    ZapAuthMode.BearerTokenHeader,
    ZapAuthMode.CustomHeader,
    ZapAuthMode.CookieHeader,
  ].map((authMode) => ({
    itemKey: authMode,
    itemName: `configurations.zap.web.authModes.${authMode}`,
    isSelected: authenticationMode === authMode,
  }));

  return (
    <div className={styles.configurationBoxWrapper} data-testid='ZapApplication'>

      <ConfigurationsZapField
        dataTestid='configurations-zap-field-application-name'
        handleChangeInput={handleChangeInput}
        inputField='application_name'
        inputValue={applicationName}
        isValid={ApplicationNameValidator(applicationName, configurations)}
        type={type}
      />

      {type === AssetType.API && (
        <ConfigurationsTargetUrlField
          dataTestid='configurations-zap-api-field-target-url'
          handleChange={handleChange}
          handleChangeInput={handleChangeInput}
          inputField='target_url'
          inputValue={targetUrl}
          type={type}
        />
      )}

      {type === AssetType.WEB && (
        <ConfigurationsZapField
          dataTestid='configurations-zap-web-field-target-url'
          handleChangeInput={handleChangeInput}
          inputField='target_url'
          inputValue={targetUrl}
          isValid={ZapUrlValidator(false, targetUrl)}
          type={type}
        />
      )}

      <ConfigurationsZapField
        dataTestid='configurations-zap-field-exclude-urls'
        handleChangeInput={onChangeExcludedPaths}
        inputField='exclude_paths'
        inputValue={excludePaths?.join(', ') || ''}
        isMultiLine
        type={type}
      />

      <ConfigurationsZapField
        handleChangeInput={handleChangeInput}
        inputField='api_domain'
        inputValue={apiDomain!}
        isValid={ZapDomainValidator(apiDomain)}
        type={type}
      />

      <ConfigurationsCheckboxField
        disabled={!uiShowZapAuthenticationMode}
        isChecked={isAuthChecked && uiShowZapAuthenticationMode}
        label='configurations.zap.enableAuthentication.label'
        onChange={onChangeAuthenticationModeCheckbox}
        testid='zap-authentication'
        tooltipText={tooltipText}
      />

      {isAuthChecked && uiShowZapAuthenticationMode && (
        <>
          <div className={styles.dropdownWrapper}>
            <JitDropdownNew
              displayText={`configurations.zap.web.authModes.${authenticationMode}`}
              menuItems={authModeDropdownOptions}
              onMenuItemClick={({ itemKey }) => onChangeAuthenticationMode(itemKey as ZapAuthMode)}
              width={240}
            />
          </div>

          <div className={styles.extraFieldsWrapper}>
            <ConfigurationsZapExtraFields
              configurations={currentConfiguration}
              handleChange={handleChange}
              handleChangeInput={handleChangeInput}
              type={type}
            />
          </div>
        </>
      )}

    </div>
  );
};

/* eslint-disable react/no-multi-comp */
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './BaseCard.module.scss';

import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  title: string;
  link?: string;
}

const TitleWithoutLink = React.memo(({ color = colors.white, title }: { color?: string, title: string }) => (
  <JitText align='center' color={color} muted text={title} />
));

const TitleWithLink = React.memo(({ link, navigate, title }: { link?: string, navigate: Function, title: string }) => (
  <JitLink
    onClick={() => navigate(link || '')}
    style={{
      textDecoration: 'none',
      cursor: 'pointer',
    }}
  >
    <TitleWithoutLink color={colors.lightBlue} title={title} />
  </JitLink>
));

export const BaseCard: FC<Props> = ({ title, link, children }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      {link ? (
        <TitleWithLink link={link} navigate={navigate} title={title} />
      ) : (
        <TitleWithoutLink title={title} />
      )}

      {children}
    </div>
  );
};

import { FC } from 'react';

import { PriorityBreakdown } from 'pages/Resources/components/PriorityBreakdown/PriorityBreakdown';
import { BACKLOG_ROUTE } from 'pages/Resources/components/RisksBreadcrumbs/RisksBreadcrumbs';
import { BaseCard } from 'pages/Resources/ResourcesPage/component/BaseCard/BaseCard';

interface Props {
  critical: number;
  high: number;
  medium: number;
}

export const FindingsCountCard: FC<Props> = ({ critical, high, medium }) => (
  <BaseCard link={BACKLOG_ROUTE} title='pages.risks.resources.resourcesPage.cards.findingsCount.description'>
    <PriorityBreakdown critical={critical} high={high} medium={medium} textSize='s' />
  </BaseCard>
);
